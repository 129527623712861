<template>
  <section class="case-creation" v-loading="loading">
    <div class="case-creation__step case-creation__billing-information">
      <div class="case-creation__card">
        <div class="case-creation__heading">
          <div class="title-with-icon">
            <span class="icon">
              <img
                src="@/assets/images/icons/icon-billing-info.svg"
                alt="icon"
                class="img-fluid"
              />
            </span>
            <h3 class="title">Billing Category</h3>
          </div>
          <div v-if="viewForm">
            <el-button @click="editCategory()" class="editButton mr-5">
              <el-icon :size="size" :color="color">
                <Edit />
              </el-icon>
              Edit
            </el-button>
          </div>
        </div>
        <div class="case-creation__body padding-0" v-if="viewForm">
          <div class="billing-information-tabs-view">
            <el-row :gutter="30">
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">Billing Category</h4>
                  <p
                    class="value"
                    v-if="
                      this.getCaseInformation &&
                      this.getCaseInformation.billing_status
                    "
                  >
                    {{
                      this.getCaseInformation.billing_status
                        ? this.getCaseInformation.billing_status
                        : "--"
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">Billing sub category</h4>
                  <p
                    class="value"
                    v-if="
                      this.getCaseInformation &&
                      this.getCaseInformation.billing_sub_status
                    "
                  >
                    {{
                      this.getCaseInformation.billing_sub_status
                        ? this.getCaseInformation.billing_sub_status
                        : "--"
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">CP Notes</h4>
                  <p
                    class="value"
                    v-if="
                      this.getCaseInformation &&
                      this.getCaseInformation.billing_status_notes
                    "
                  >
                    {{
                      this.getCaseInformation.billing_status_notes
                        ? this.getCaseInformation.billing_status_notes
                        : "--"
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">Coding Notes</h4>
                  <p
                    class="value"
                    v-if="
                      this.getCaseInformation &&
                      this.getCaseInformation.billing_notes
                    "
                  >
                    {{
                      this.getCaseInformation.billing_notes
                        ? this.getCaseInformation.billing_notes
                        : "--"
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">AR Notes</h4>
                  <p
                    class="value"
                    v-if="
                      this.getCaseInformation &&
                      this.getCaseInformation.ar_notes
                    "
                  >
                    {{
                      this.getCaseInformation.ar_notes
                        ? this.getCaseInformation.ar_notes
                        : "--"
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="case-creation__body padding-0" v-else>
          <div class="billing-information-tabs-view" v-loading="loading">
            <el-row :gutter="30">
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">Billing category</h4>
                  <el-select 
                    v-model="billing_status"
                    allow-create
                    filterable 
                    id="category"
                  >
                    <el-option
                      v-for="(billing, index) of billingOptions"
                      :key="index"
                      :value="billing"
                      :label="billing"
                    ></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">Billing Subcategory</h4>
                  <el-select 
                    v-model="billing_sub_status"
                    allow-create
                    filterable 
                    id="subcategory"
                  >
                    <el-option
                      v-for="(billing, index) of filteredSubcategoryOptions"
                      :key="index"
                      :value="billing"
                      :label="billing"
                    ></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">CP Notes</h4>
                  <el-input
                    v-model="billing_status_notes"
                    placeholder="Enter CP Notes"
                    id="subcategory"
                  >
                  </el-input>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">Coding Notes</h4>
                  <el-input
                    v-model="billing_notes"
                    placeholder="Enter Coding Notes"
                    id="subcategory"
                  >
                  </el-input>
                </div>
              </el-col>
              <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                <div class="input-value view-cases">
                  <h4 class="name">AR Notes</h4>
                  <el-input
                    v-model="ar_notes"
                    placeholder="Enter AR Notes"
                    id="subcategory"
                  >
                  </el-input>
                </div>
              </el-col>
            </el-row>
          </div>
          <ul class="action-button-group">
            <li>
              <el-button class="add-btn" @click="updateBillingCategory"
                >Update</el-button
              >
            </li>
            <li>
              <el-button class="add-btn" @click="cancelEdit()"
                >Cancel</el-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import {
  billingCategoryOptions,
  billingSubCategoryOptions,
} from "@/config/constants/searchConstants";

export default {
  data() {
    return {
      loading: false,
      case_id: "",
      billing_status: "",
      billing_sub_status: "",
      billing_status_notes: "",
      billing_notes: "",
      ar_notes: "",
      viewForm: false,
      billingOptions: billingCategoryOptions,
      insuranceEligibilityDetails: {},
      foundCoverages: [],
    };
  },
  mixins: [UserPermissionsHelper],
  async mounted() {
    if (this.$route.params.case_id) {
      this.case_id = this.$route.params.case_id;
      await this.fetchCaseInformation();
      await this.fetchInsuracneEligibilityDetails()
      this.fetchCaseData();
    }
  },
  computed: {
    ...mapGetters("cases", [
      "getCaseInformation",
      "getRXNTSendStatus",
      "getBillingCategoryUpdateStatus",
      "getInsuranceEligibilityDetails"
    ]),
    filteredSubcategoryOptions() {
      return billingSubCategoryOptions[this.billing_status] || [];
    },
  },
  methods: {
    async fetchInsuracneEligibilityDetails(){
      try {
        await this.$store.dispatch(
          "cases/fetchInsuranceEligibleDetails",
          this.case_id
        );
        if (
          this.getInsuranceEligibilityDetails &&
          this.getInsuranceEligibilityDetails.data
        ) {
          this.insuranceEligibilityDetails = {
            ...this.getInsuranceEligibilityDetails.data
              .insurance_eligibility_verified_details,
          };          
          this.foundCoverages = this.insuranceEligibilityDetails.foundCoverages;
          if (
            this.getInsuranceEligibilityDetails.data.insurance_eligibility_verified === true &&
            this.getCaseInformation &&
            this.getCaseInformation.billing_status === 'Unbilled' &&
            this.foundCoverages && this.foundCoverages.length > 0 &&
            this.foundCoverages[0].coverageStatus === 1 &&
            this.foundCoverages && this.foundCoverages[0].summary &&
            (this.foundCoverages[0].summary.individualDeductibleRemaining === null ||
            this.foundCoverages[0].summary.individualDeductibleRemaining === 0)
          ) {
            let cpNotes = `Good to bill -Insurance ${this.foundCoverages[0].payerName} is active from ${this.formatDate(this.foundCoverages[0].summary.policyStart) || "--"} effective date to ${this.formatDate(this.foundCoverages[0].summary.policyEnd) || "--"} and plan type ${this.foundCoverages[0].summary.name} total deductible $ ${ this.foundCoverages[0].summary.individualDeductible || 0} and remaining $ ${this.foundCoverages[0].summary.individualDeductibleRemaining || 0} hence move the claim  to for coding ${this.foundCoverages[0].ruleResult ?  '-' +this.foundCoverages[0].ruleResult.Hospice : ""}`;
            let params = {
              case_id: this.case_id,
              billing_status: "Coding",
              billing_sub_status: "For Coding",
              billing_status_notes: cpNotes,
              billing_notes: "",
              ar_notes: "",
            };
            await this.$store.dispatch("cases/updateBillingCategory", params);
            window.location.reload()
          }
          if (
            this.getInsuranceEligibilityDetails.data.insurance_eligibility_verified === true &&
            this.getCaseInformation &&
            this.getCaseInformation.billing_status === 'Unbilled' &&
            this.foundCoverages && this.foundCoverages.length > 0 &&
            this.foundCoverages[0].coverageStatus === 1 &&
            this.foundCoverages[0].summary &&
            this.foundCoverages[0].summary.individualDeductibleRemaining != null &&
            this.foundCoverages[0].summary.individualDeductibleRemaining !== 0
          ) {
              let cpNotes = `Deductible not met - Insurance ${this.foundCoverages[0].payerName} is active from ${this.formatDate(this.foundCoverages[0].summary.policyStart) || "--"} effective date to ${this.formatDate(this.foundCoverages[0].summary.policyEnd) || "--"} and plan type ${this.foundCoverages[0].summary.name} total deductible $ ${this.foundCoverages[0].summary.individualDeductible || 0} and remaining $ ${this.foundCoverages[0].summary.individualDeductibleRemaining || 0} hence move the claim to for coding ${this.foundCoverages[0].ruleResult ?  '-' +this.foundCoverages[0].ruleResult.Hospice : ""}`;
              let params = {
                  case_id: this.case_id,
                  billing_status: "Coding",
                  billing_sub_status: "For Coding",
                  billing_status_notes: cpNotes,
                  billing_notes: "",
                  ar_notes: "",
              };
              await this.$store.dispatch("cases/updateBillingCategory", params);
              window.location.reload();
          }
          if (
            this.getInsuranceEligibilityDetails.data.insurance_eligibility_verified === false &&
            this.getCaseInformation &&
            this.getCaseInformation.billing_status === 'Unbilled' &&
            this.foundCoverages && this.foundCoverages[0].payerName &&
            this.foundCoverages && this.foundCoverages[0].rejectionReason
          ) {
            let cpNotes = `Exception - verify the Insurance ${this.foundCoverages && this.foundCoverages[0].payerName} is not eligible for the DOS as ${this.foundCoverages && this.foundCoverages[0].rejectionReason} ${this.foundCoverages[0].ruleResult ?  '-' +this.foundCoverages[0].ruleResult.Hospice : ""}`;
            let params = {
              case_id: this.case_id,
              billing_status: "Coding",
              billing_sub_status: "For Coding",
              billing_status_notes: cpNotes,
              billing_notes: "",
              ar_notes: "",
            };
            await this.$store.dispatch("cases/updateBillingCategory", params);
            window.location.reload()
          } 
        }
      } catch (err) {
        console.log(err);
      }
    },
    editCategory() {
      this.viewForm = false;
      this.fetchCaseInformation();
    },
    cancelEdit() {
      this.viewForm = true;
    },
    async fetchCaseInformation() {
      try {
        let case_id = this.$route.params.case_id;
        await this.$store.dispatch("cases/fetchCaseInformation", case_id);
        if (this.getCaseInformation) {
          this.billing_status = this.getCaseInformation.billing_status || "";
          this.billing_sub_status =
            this.getCaseInformation.billing_sub_status || "";
          this.billing_status_notes =
            this.getCaseInformation.billing_status_notes || "";
          this.billing_notes = this.getCaseInformation.billing_notes || "";
          this.ar_notes = this.getCaseInformation.ar_notes || "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchCaseData() {
      if (
        this.getCaseInformation &&
        (this.getCaseInformation.billing_status ||
          this.getCaseInformation.billing_sub_status ||
          this.getCaseInformation.billing_status_notes ||
          this.getCaseInformation.billing_notes ||
          this.getCaseInformation.ar_notes)
      ) {
        this.viewForm = true;
        this.billing_status = this.getCaseInformation.billing_status;
        this.billing_sub_status = this.getCaseInformation.billing_sub_status;
        this.billing_status_notes =
          this.getCaseInformation.billing_status_notes;
        this.billing_notes = this.getCaseInformation.billing_notes;
        this.ar_notes = this.getCaseInformation.ar_notes;
      } else {
        this.viewForm = false;
      }
    },
    async updateBillingCategory() {
      try {
        this.loading = true;
        let params = {
          case_id: this.case_id,
          billing_status: this.billing_status,
          billing_sub_status: this.billing_sub_status,
          billing_status_notes: this.billing_status_notes,
          billing_notes: this.billing_notes,
          ar_notes: this.ar_notes,
        };
        await this.$store.dispatch("cases/updateBillingCategory", params);
        this.loading = false;
        if (this.getBillingCategoryUpdateStatus) {
          successNotification("Category updated successfully");
          await this.fetchCaseInformation();
          this.viewForm = true;
        } else {
          errorNotification("Error while Updating billing status");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sentToRXNT() {
      this.loading = true;
      try {
        let params = {
          case_id: this.case_id,
        };
        await this.$store.dispatch("cases/sendAccessionToRXNT", params);
        if (this.getRXNTSendStatus) {
          successNotification("Order sent to RXNT successfully");
          window.location.reload();
        } else {
          errorNotification("Error while sending order to RXNT");
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  watch: {
    billing_status(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.billing_sub_status = "";
      }
    },
  },
};
</script>

<style lang="scss">
.case-creation__billing-information {
  .delete-billing-info {
    margin-right: 20px;
    padding: 5px 0;

    button {
      background: #f24a55;
      min-height: 25px;
      border: none;
      padding: 5px 20px;
    }
  }
  .action-button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px 20px 20px;
    li {
      &:not(:first-child) {
        margin-left: 10px;
      }
      button {
        padding: 0 15px;
        background-color: #273480;
        color: #ffffff;
        min-height: 32px;
        line-height: 1;
        border: none;
        border-radius: 6px;
        text-align: center;
        margin: 0;
        &.is-disabled {
          background: #9d9d9d;
          color: #fff;
        }
      }
      &:nth-child(2) {
        button {
          background: #f24a55;
        }
      }
    }
  }
  .editButton {
    background: #f24a55;
    border: none;
    color: #ffffff;
    padding: 7px 12px;
    min-height: 31px;
    border: none;
    border-radius: 6px;
  }
  .sent-billing-info {
    margin-right: 40px;
    padding: 5px 0;
    color: blue;
  }
  .case-creation__body {
    &.padding-0 {
      padding: 0 !important;
    }
    .billing-information-tabs-view {
      padding: 20px 20px 0;
    }
  }
}
</style>
